import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import ReactTooltip from "react-tooltip"
import styled from "@emotion/styled"
import { colors } from "../styles/theme"
import {
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share"

const Share = styled.div`
  display: grid;
  margin-top: 4rem;
  border-top: 1px solid grey;
  padding-top: 2rem;

  @media (min-width: 421px) {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    grid-template-areas: "s-label s-email s-twtr s-li s-fb s-wtsap s-rdt s-todo s-todo s-todo";
  }

  @media (min-width: 320px) and (max-width: 420px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "s-label"
      "s-email"
      "s-twtr"
      "s-li"
      "s-fb"
      "s-wtsap"
      "s-rdt";
  }
`

const ShareLabel = styled.span`
  grid-area: s-label;
  justify-self: center;
  align-self: center;

  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  color: ${colors.textDark};
`

const EmailShare = styled(EmailShareButton)`
  cursor: pointer;
  // border: 1px solid red;

  @media (min-width: 421px) {
    justify-self: start;
    margin-left: 1rem;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    margin-top: 0.5rem;
    justify-self: center;
  }

  &:hover {
    opacity: 0.75;
  }
`

const TwitterShare = styled(TwitterShareButton)`
  cursor: pointer;
  // border: 1px solid red;

  @media (min-width: 421px) {
    justify-self: start;
    margin-left: 1rem;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    margin-top: 0.5rem;
    justify-self: center;
  }

  &:hover {
    opacity: 0.75;
  }
`

const LinkedinShare = styled(LinkedinShareButton)`
  cursor: pointer;
  // border: 1px solid red;

  @media (min-width: 421px) {
    justify-self: start;
    margin-left: 1rem;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    margin-top: 0.5rem;
    justify-self: center;
  }

  &:hover {
    opacity: 0.75;
  }
`

const FacebookShare = styled(FacebookShareButton)`
  cursor: pointer;
  // border: 1px solid red;

  @media (min-width: 421px) {
    justify-self: start;
    margin-left: 1rem;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    margin-top: 0.5rem;
    justify-self: center;
  }

  &:hover {
    opacity: 0.75;
  }
`

const WhatsappShare = styled(WhatsappShareButton)`
  cursor: pointer;
  // border: 1px solid red;

  @media (min-width: 421px) {
    justify-self: start;
    margin-left: 1rem;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    margin-top: 0.5rem;
    justify-self: center;
  }

  &:hover {
    opacity: 0.75;
  }
`

const RedditShare = styled(RedditShareButton)`
  cursor: pointer;
  // border: 1px solid red;

  @media (min-width: 421px) {
    justify-self: start;
    margin-left: 1rem;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    margin-top: 0.5rem;
    justify-self: center;
  }

  &:hover {
    opacity: 0.75;
  }
`

const SocialShare = ({ socialConfig }) => (
  <StaticQuery
    query={query}
    render={queryResult => {
      const metadata = queryResult.site.siteMetadata
      const url = socialConfig.slug
        ? `${metadata.siteUrl}/${socialConfig.slug}/`
        : metadata.siteUrl
      const title = socialConfig.title || metadata.title

      const emailSubject =
        socialConfig.email && socialConfig.email.subject
          ? socialConfig.email.subject
          : title
      const emailBody =
        socialConfig.email && socialConfig.email.body
          ? socialConfig.email.body
          : ``
      const twitterTitle =
        socialConfig.twitter && socialConfig.twitter.title
          ? socialConfig.twitter.title
          : title
      const twitterHashtag =
        socialConfig.twitter && socialConfig.twitter.hashtags
          ? socialConfig.twitter.hashtags
          : null
      const facebookQuote =
        socialConfig.facebook && socialConfig.facebook.quote
          ? socialConfig.facebook.quote
          : title
      const facebookHashtag =
        socialConfig.facebook && socialConfig.facebook.hashtag
          ? socialConfig.facebook.hashtag
          : null
      const watsappTitle =
        socialConfig.watsapp && socialConfig.watsapp.title
          ? socialConfig.watsapp.title
          : title
      const redditTitle =
        socialConfig.reddit && socialConfig.reddit.title
          ? socialConfig.reddit.title
          : title

      return (
        <Share>
          <ShareLabel>Share this article: </ShareLabel>
          <EmailShare url={url} subject={emailSubject} body={emailBody}>
            <span data-tip="React-tooltip" data-for="shareEmail">
              <EmailIcon size={48} round={true} />
            </span>
            <ReactTooltip
              id="shareEmail"
              place="top"
              type="dark"
              effect="solid"
            >
              <span>Email</span>
            </ReactTooltip>
          </EmailShare>
          <TwitterShare
            url={url}
            title={twitterTitle}
            hashtags={twitterHashtag ? twitterHashtag : undefined}
          >
            <span data-tip="React-tooltip" data-for="shareTwitter">
              <TwitterIcon size={48} round={true} />
            </span>
            <ReactTooltip
              id="shareTwitter"
              place="top"
              type="dark"
              effect="solid"
            >
              Twitter
            </ReactTooltip>
          </TwitterShare>
          <LinkedinShare url={url}>
            <span data-tip="React-tooltip" data-for="shareLinkedin">
              <LinkedinIcon size={48} round={true} />
            </span>
            <ReactTooltip
              id="shareLinkedin"
              place="top"
              type="dark"
              effect="solid"
            >
              LinkedIn
            </ReactTooltip>
          </LinkedinShare>
          <FacebookShare
            url={url}
            quote={facebookQuote}
            hashtag={facebookHashtag ? facebookHashtag : undefined}
          >
            <span data-tip="React-tooltip" data-for="shareFb">
              <FacebookIcon size={48} round={true} />
            </span>
            <ReactTooltip id="shareFb" place="top" type="dark" effect="solid">
              Facebook
            </ReactTooltip>
          </FacebookShare>
          <WhatsappShare url={url} title={watsappTitle}>
            <span data-tip="React-tooltip" data-for="shareWatsapp">
              <WhatsappIcon size={48} round={true} />
            </span>
            <ReactTooltip
              id="shareWatsapp"
              place="top"
              type="dark"
              effect="solid"
            >
              Watsapp
            </ReactTooltip>
          </WhatsappShare>
          <RedditShare url={url} title={redditTitle}>
            <span data-tip="React-tooltip" data-for="shareReddit">
              <RedditIcon size={48} round={true} />
            </span>
            <ReactTooltip
              id="shareReddit"
              place="top"
              type="dark"
              effect="solid"
            >
              Reddit
            </ReactTooltip>
          </RedditShare>
        </Share>
      )
    }}
  />
)

SocialShare.propTypes = {
  socialConfig: PropTypes.shape({
    slug: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.shape({
      subject: PropTypes.string,
      body: PropTypes.string,
      openWindow: PropTypes.bool,
    }),
    twitter: PropTypes.shape({
      title: PropTypes.string,
      via: PropTypes.any,
      hashtags: PropTypes.any,
    }),
    facebook: PropTypes.shape({
      quote: PropTypes.any,
      hashtag: PropTypes.any,
    }),
    watsapp: PropTypes.shape({
      title: PropTypes.string,
      separator: PropTypes.any,
    }),
    reddit: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
}

SocialShare.defaultProps = {
  socialConfig: {},
}

export default SocialShare

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        author {
          name
          minibio
        }
        social {
          twitter
          fbAppID
          github
          linkedin
          stackoverflow
        }
      }
    }
  }
`
