import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from "@emotion/styled"
import SEO from "../component/SEO/seo"
import Layout from "../component/layout"
import SocialShare from "../component/share"
import {colors } from "../styles/theme"


export const query = graphql`
    query($slug: String!) {
        post: mdx(frontmatter: { slug: { eq: $slug } }) {
            body
            frontmatter {
                title
                subtitle
                category
                tags
                date
                image {
                    childImageSharp {
                        fluid {
                            src
                        }
                    }
                }
                seo_title
                author
                slug
            }
        }
    }
`;

export default ({ data: { post } }) => {
    const seo = {
        frontmatter: {
            title: post.frontmatter.title,
            description: post.frontmatter.subtitle,
            slug: post.frontmatter.slug,
            datePublished: post.frontmatter.date,
        },
        postImage: post.frontmatter.image ? post.frontmatter.image.childImageSharp.fluid.src : null,
        isBlogPost: true,
    };

    const social = {
        socialConfig: {
            title: post.frontmatter.title,
            slug: post.frontmatter.slug,
        }
    }

    return (
        <Layout>
            <SEO {...seo} />
            <Section>
                <h2>{post.frontmatter.title}</h2>
                <p>
                    <PubDate>Published {post.frontmatter.date}, <LineBreakOnMobile/>in &nbsp;</PubDate>
                    <Category>{post.frontmatter.category}</Category>
                </p>
            </Section>
            <Article>
                <MDXRenderer>{post.body}</MDXRenderer>
            </Article>
            <SocialShare {...social} />
        </Layout>
    )
};

const Section = styled.section`
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
    h2 {
        font-family: proxima-nova, sans-serif;
        font-weight: 100;
        font-style: normal;
        font-size: 2.25rem;
        color: ${colors.darkestGrey};
    }

    @media (min-width: 320px) and (max-width: 767px) {
        margin-right: 1rem;
        margin-left: 1rem;
    }

    @media (min-width: 768px) and (max-width: 1000px) {
        margin-right: 2rem;
        margin-left: 2rem;
    }
`;


const PubDate = styled.span`
    font-family: source-sans-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.25rem;
    color: ${colors.textLight};
`;

const LineBreakOnMobile = styled.br`
    display: none;
    @media (min-width: 320px) and (max-width: 767px) {
        display: initial;
    }
`;

const Category = styled.span`
    font-family: source-sans-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 1.125rem;
    padding: 0.1rem 0.3rem;
    letter-spacing: 1px;

    text-transform: uppercase;
    color: white;
    background-color: ${colors.backgroundGrey};
    opacity: 0.75;

    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; /* future proofing */
    -khtml-border-radius: 3px; /* for old Konqueror browsers */
`;


const Article = styled.article`
    font-family: source-sans-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.25rem;
    color: ${colors.textDark};

    strong {
        font-family: source-sans-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 1.25rem;
        color: ${colors.textDark};
    }

    h2 {
        font-family: source-sans-pro, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        color: ${colors.textDark};
    }

    h3 {
        font-family: source-sans-pro, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        color: ${colors.textDark};
    }

    h4 {
        font-family: source-sans-pro, sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        color: ${colors.textDark};
    }

    p {
        margin-bottom: 1rem;
    
        @media (min-width: 320px) and (max-width: 767px) {
            margin-bottom: 2rem;
        }

        @media (min-width: 768px) and (max-width: 1000px) {
            margin-bottom: 1.5rem;
        }
    }

    a {
        background: linear-gradient(to bottom, ${colors.gold} 0%, ${colors.gold} 100%);
        background-position: 0 100%;
        background-repeat: repeat-x;
        background-size: 4px 4px;
        color: #000;
        text-decoration: none;
        transition: background-size 0.2s;

        &:hover {
            background-size: 4px 50px;
        }
    }

    pre {
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: ${colors.darkestGrey};
      
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border-radius: 4px; /* future proofing */
        -khtml-border-radius: 4px; /* for old Konqueror browsers */
    }

    @media (min-width: 320px) and (max-width: 767px) {
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: 1.125rem;
    }

    @media (min-width: 768px) and (max-width: 1000px) {
        margin-right: 2rem;
        margin-left: 2rem;
    }
`;

